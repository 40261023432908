import React from 'react';
import Typewriter from 'typewriter-effect';

// Shape Images
import Shape1 from "../../assets/images/shape1.png";
import Shape2 from "../../assets/images/shape2.svg";
import Shape3 from "../../assets/images/shape3.svg";
import Shape4 from "../../assets/images/shape4.svg";
import IOTMainImg1 from "../../assets/images/banner-image.png"

const MainBanner = () => {
    return (
        <div className="hosting-main-banner" id={'main'}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="ml-banner-content transition">
                            <h1> <b> Your partner in </b>
                                <Typewriter
                                    options={{
                                        strings: ['<b>Customer Experience.</b>', '<b>Digital Transformation.</b>'],
                                        autoStart: true,
                                        loop: true,
                                    }}
                                />
                            </h1>
                        </div>
                    </div>
                </div>
              <div className="iot-banner-image">
                <img
                  src={IOTMainImg1}
                  className="animate__animated animate__fadeInUp animate__delay-0.8s"
                  alt="banner"
                />
              </div>

              <div className="animate-border">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>

            {/* Shape Images */}
            <div className="shape1">
                <img src={Shape1} alt="shape" />
            </div>
            <div className="shape2 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
            <div className="shape3">
                <img src={Shape3} alt="shape" />
            </div>
            <div className="shape4">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape7">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape8 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
        </div>
    );
}

export default MainBanner;
