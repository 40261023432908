import React from 'react';
import Education from "../../assets/images/our-feature/education.png";
import Finance from "../../assets/images/our-feature/finance.png";
import Consulting from "../../assets/images/our-feature/consulting.png";
import Government from "../../assets/images/our-feature/government.png";
import Manufacturing from "../../assets/images/our-feature/manufacturing.png";
import Sports from "../../assets/images/our-feature/sports.png";
import NonProfit from "../../assets/images/our-feature/non-profit.png";
import Shape1 from "../../assets/images/shape1.png"
import Shape2 from "../../assets/images/shape2.svg"
import Shape3 from "../../assets/images/shape3.svg"
import Shape4 from "../../assets/images/shape4.svg"

const OurFeatures = () => {

    return (
        <div className="features-area pt-80 pb-50 ml-feedback-area" id={"verticals"}>
            <div className="container">
                <div className="section-title">
                    <h2>Our Focus Verticals</h2>
                    <div className="bar"></div>
                </div>

                <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6" key={1}>
                            <div className="single-features">
                                <div className="icon">
                                    <img src={Consulting} className="map" alt="map" />
                                </div>
                                <h3>
                                        Consulting
                                </h3>
                            </div>
                        </div>
                    <div className="col-lg-4 col-md-6" key={1}>
                        <div className="single-features">
                            <div className="icon">
                                <img src={Education} className="map" alt="map" />
                            </div>
                            <h3>
                                    Education
                            </h3>
                        </div>
                    </div><div className="col-lg-4 col-md-6" key={1}>
                    <div className="single-features">
                        <div className="icon">
                            <img src={Finance} className="map" alt="map" />

                        </div>
                        <h3>
                                Finance
                        </h3>
                    </div>
                </div><div className="col-lg-4 col-md-6" key={1}>
                    <div className="single-features">
                        <div className="icon">
                            <img src={Government} className="map" alt="map" />

                        </div>
                        <h3>
                                Government
                        </h3>
                    </div>
                </div><div className="col-lg-4 col-md-6" key={1}>
                    <div className="single-features">
                        <div className="icon">
                            <img src={Manufacturing} className="map" alt="map" />
                        </div>
                        <h3>
                            Manufacturing
                        </h3>
                    </div>
                </div><div className="col-lg-4 col-md-6" key={1}>
                    <div className="single-features">
                        <div className="icon">
                            <img src={NonProfit} className="map" alt="map" />
                        </div>
                        <h3>
                            Not for Profits
                        </h3>
                    </div>
                </div><div className="col-lg-4 col-md-6" key={1}>
                    <div className="single-features">
                        <div className="icon">
                            <img src={Sports} className="map" alt="map" />

                        </div>
                        <h3>
                            Fitness and Wellbeing
                        </h3>
                    </div>
                </div>
                </div>
            </div>
            {/* Shape Images */}
            <div className="shape1">
                <img src={Shape1} alt="shape" />
            </div>
            <div className="shape2 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
            <div className="shape3">
                <img src={Shape3} alt="shape" />
            </div>
            <div className="shape4">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape7">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape8 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
        </div>
    )
}

export default OurFeatures;
