import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/Navbar";
import MainBanner from '../components/pages/MainBanner';
import AboutUsContent from '../components/pages/AboutUsContent';
import Footer from "../components/_App/Footer";
import OurFeatures from "../components/pages/OurFeatures";
import ServicesArea from "../components/pages/ServicesArea";
import ProjectStyle1 from "./ProjectStyle1";
import Partner from "../components/Common/Partner";

const MachineLearning = () => (
    <Layout>
      <SEO title="DoIT Cloud - Your partner in Customer Experience & Digital Transformation." />
      <Navbar />
      <MainBanner />
      <ProjectStyle1 />
      <OurFeatures />
      <ServicesArea />
      <Partner />
      <AboutUsContent />
      <Footer />
    </Layout>
)

export default MachineLearning;
