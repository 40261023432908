import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import Shape1 from "../../assets/images/shape1.png"
import Shape2 from "../../assets/images/shape2.svg"
import Shape3 from "../../assets/images/shape3.svg"
import Shape4 from "../../assets/images/shape4.svg"

const query = graphql`
    {
        strapiPartner {
            subTitle    
            partnerLogo {
                id
                mainImg {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                link
            }
        }
    }
`

const Partner = () => {

    const data = useStaticQuery(query)
    const {
        strapiPartner
    } = data

    return (
        <>
            <div className="ready-to-talk ptb-80 ml-feedback-area">
                <div className="container">
                    <div className="section-title">
                      <h3>{strapiPartner.subTitle}</h3>
                      <div className="bar"/>
                    </div>
                </div>
              {/* Shape Images */}
                <div className="shape1" >
                  <img src={Shape1} alt="shape" />
                </div>
                <div className="shape2 rotateme">
                  <img src={Shape2} alt="shape" />
                </div>
                <div className="shape3">
                  <img src={Shape3} alt="shape" />
                </div>
                <div className="shape4">
                  <img src={Shape4} alt="shape" />
                </div>
                <div className="shape7">
                  <img src={Shape4} alt="shape" />
                </div>
                <div className="shape8 rotateme">
                  <img src={Shape2} alt="shape" />
                </div>
            </div>

            <div className="partner-area partner-section">
                <div className="container">

                    <div className="partner-inner">
                        <div className="row justify-content-center">
                            {strapiPartner.partnerLogo.map(pLogo => (
                                <div className="col-lg-4 col-md-3 col-6" key={pLogo.id}>
                                    <a href={pLogo.link} target="_blank" rel={'noreferrer'}>
                                        <img
                                            width={'264px'}
                                            height={'109px'}
                                            src={pLogo.mainImg.childImageSharp.fluid.src}
                                            alt={pLogo.link || 'logo'}
                                        />
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );

}

export default Partner;
