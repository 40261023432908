import React from 'react';
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
    {
        strapiCloudHostingServices {
            title
            shortText
            serviceList {
                id
                text
                image {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
			          }
            }
            image {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}
        },        
    }
`

const ServicesArea = () => {

    const data = useStaticQuery(query)
    const {
        strapiCloudHostingServices
    } = data

    return (
        <>
            <div className="services-area ptb-80 bg-f7fafd" id={'certifications'}>
                <div className="container">
                    <div className="services-content">
                        <div className="section-title">
                            <h2>{strapiCloudHostingServices.title}</h2>
                            <div className="bar"></div>
                            <p>{strapiCloudHostingServices.shortText}</p>
                        </div>
                        <div className="row justify-content-center">
                            {strapiCloudHostingServices.serviceList.map(list => (
                                <div className="col-lg-4 col-md-6" key={list.id}>
                                    <div className="single-features">
                                        <div className={'certificates'} >
                                            <img src={list.image.childImageSharp.fluid.src}  alt={'certificates'}/> </div>
                                        <h3>{list.text}</h3>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServicesArea;
