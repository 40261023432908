import React from 'react';

import Shape1 from "../../assets/images/shape1.png";
import Shape2 from "../../assets/images/shape2.svg";
import Shape3 from "../../assets/images/shape3.svg";
import Shape4 from "../../assets/images/shape4.svg";
import AboutOne from "../../assets/images/banner-bg.jpg";

const AboutUsContent = () => {
    return (
        <div className="about-area bg-f7fafd" id={"about-area"}>
            <div className="container-fluid">
              <div className="section-title">
                <h2>About Us</h2>
                <div className="bar"></div>
              </div>
                <div className="about-area-main">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12">
                                <div className="about-image">
                                    <img src={AboutOne} width={843} height={460} alt="About us" />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <div className="about-content">
                                    <div className="section-title about-us">
                                        <p> DoIT Cloud Ltd was founded in 2014 to provide niche consultancy in the Salesforce.com space.
                                          DoIT Cloud has been formed by a number of well experienced consultants who have delivered projects for SME and large enterprise world wide.
                                          The combined skills and experience allow us to provide an excellent service and ability to deliver projects using the latest Salesforce.com technologies and deliver tangible benefits to businesses.
                                        </p>
                                        <br />
                                        <p>
                                          Our aim is to help businesses through their digital transformation projects, improving sales, better manage their customers, products, partners, and even employees.
                                          We are here to unleash the full potential of your investment in the Salesforce.com platform.
                                          We offer services of the highest quality that cater to the requirements of our clients perfectly and make sure our clients achieve their organizational goals with cost effective solutions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="about-inner-area">
                            <div className="row justify-content-center">
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="about-text">
                                        <h3>Why Us</h3>
                                        <p>From small to large enterprises across all business verticals, we help the organizations making the most out of their Salesforce.com platform offering end to end Salesforce development solutions and consultation.
                                          Starting from Salesforce Lightning, Force.com to the cloud services like Salesforce Sales Cloud, Marketing Cloud, Service Cloud, CPQ, Salesforce Wave Analytics, Salesforce Einstein AI, Pardot, Salesforce Mobile Applications, App exchange Applications and more.
                                          All our consultants are Salesforce Certified, please see “Certifications”. </p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="about-text">
                                        <h3>What we do</h3>
                                        <p>
                                          DoIT Cloud supports organisations on their strategic and digital transformation journeys by providing an integrated, structured approach to achieving outcomes.
                                          We know that technology is only half the battle so we focus on the way initiatives are implemented to ensure real benefits are achieved.
                                          Our proven end to end success framework ensures customer centrality and business needs are fully aligned with the challenges of delivery.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Shape Images */}
            <div className="shape1">
                <img src={Shape1} alt="shape" />
            </div>
            <div className="shape2 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
            <div className="shape3">
                <img src={Shape3} alt="shape" />
            </div>
            <div className="shape4">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape7">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape8 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
        </div>
    );
}

export default AboutUsContent;
